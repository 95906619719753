import React from 'react'
import Header from './header'
import '../styles/layout.css'
import styles from './layout.module.css'
import {RiTwitterLine, RiLinkedinBoxLine, RiGithubLine} from 'react-icons/ri'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle}) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
          <a className={styles.link} href='https://www.twitter.com/gabrielhl/'><RiTwitterLine /><span className={styles.text}>Twitter</span></a>
          <a className={styles.link} href='https://www.linkedin.com/in/gabrielhl/'><RiLinkedinBoxLine /><span className={styles.text}>LinkedIn</span></a>
          <a className={styles.link} href='https://www.github.com/gabrielhl/'><RiGithubLine /><span className={styles.text}>GitHub</span></a>
        </div>
      </div>
    </footer>
  </>
)

export default Layout
